:root {
  --font-size-10: 10px;
  --font-size-12: 12px;
  --font-size-14: 14px;
  --font-size-15: 15px;
  --font-size-16: 16px;
  --font-size-18: 18px;
  --font-size-20: 20px;
  --font-size-24: 24px;
  --font-size-30: 30px;
  --font-size-32: 32px;
  --font-size-40: 40px;
  --font-size-48: 48px;

  --font-weight-regular: 400;
  --font-weight-bold600: 600;
  --font-weight-bold: 700;

  --pink: #ff134e;
  --pink-hover: #d01544;

  --green-light: #e6f8eb;
  --green-light-hover: #d9f4e1;
  --green-light-active: #b0e8c0;
  --green-normal: #00b635;
  --green-normal-hover: #00a430;
  --green-normal-active: #00922a;
  --green-dark: #008928;
  --green-dark-hover: #006d20;
  --green-dark-active: #005218;
  --green-darker: #004013;

  --orange-light: #fff4e6;
  --orange-light-hover: #ffeed9;
  --orange-light-active: #ffdbb0;
  --orange-normal: #ff8c00;
  --orange-normal-hover: #e67e00;
  --orange-normal-active: #cc7000;
  --orange-dark: #bf6900;
  --orange-dark-hover: #995400;
  --orange-dark-active: #733f00;
  --orange-darker: #593100;

  --red-light: #ffe6e6;
  --red-light-hover: #ffd9da;
  --red-light-active: #ffb0b2;
  --red-normal: #ff0005;
  --red-normal-hover: #e60005;
  --red-normal-active: #cc0004;
  --red-dark: #bf0004;
  --red-dark-hover: #990003;
  --red-dark-active: #730002;
  --red-darker: #590002;

  --blue-light: #e6f7fe;
  --blue-light-hover: #d9f3fd;
  --blue-light-active: #b1e6fb;
  --blue-normal: #02aff3;
  --blue-normal-hover: #029edb;
  --blue-normal-active: #028cc2;
  --blue-dark: #0283b6;
  --blue-dark-hover: #016992;
  --blue-dark-active: #014f6d;
  --blue-darker: #013d55;

  --grey-light: #f6f6f6;
  --grey-light-hover: #f1f1f1;
  --grey-light-active: #e2e2e2;
  --grey-normal: #a3a3a3;
  --grey-normal-hover: #939393;
  --grey-normal-active: #828282;
  --grey-dark: #7a7a7a;
  --grey-dark-hover: #626262;
  --grey-dark-active: #494949;
  --grey-darker: #393939;

  --black-normal: #000000;
  --black-normal-2: #31363f;
  --white-normal: #ffffff;
}

@font-face {
  font-family: "roboto";
  src: url("./assets/fonts/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "roboto-light";
  src: url("./assets/fonts/Roboto-Light.ttf") format("truetype");
}

@font-face {
  font-family: "roboto-bold";
  src: url("./assets/fonts/Roboto-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "roboto-medium";
  src: url("./assets/fonts/Roboto-Medium.ttf") format("truetype");
}

#root_body p {
  line-height: 24px;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "roboto", "Arial" !important;
  line-height: 24px;
}

/* .content-wrapper {
  margin-bottom: 35px;
} */

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("/public/Icons/chevron-down.svg");
  background-repeat: no-repeat;
  background-position: right center;
  padding-right: 20px;
  /* Adjust this value to align the icon properly */
}

table {
  border-collapse: separate;
  border-spacing: 10px;
}

* {
  font-family: "roboto", "Arial" !important;
}

.roboto {
  font-family: "roboto", "Arial" !important;
}

.roboto-bold {
  font-family: "roboto-bold", "Arial" !important;
  font-weight: var(--font-weight-bold) !important;
}

.roboto-light {
  font-family: "roboto-light", "Arial" !important;
}

.roboto-medium {
  font-family: "roboto-medium", "Arial" !important;
}

.sidenav---sidenav---_2tBP {
  background: #ffffff !important;
  display: block;
  height: 100vh;
  position: fixed !important;
}

.navbar {
  background: white !important;
  padding: 5px 0px 0px 0px !important;
}

#root_div {
  position: relative;
  background: #f5f5f5;
  box-sizing: border-box;
  overflow: hidden;
}

#root_body {
  padding: 20px 30px 0px 30px;
  margin-bottom: 65px;
}

.sidenav---icon-bar---u1f02 {
  background: #8d8d8d !important;
}

.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ-:hover>.sidenav---navitem---9uL5T .sidenav---navicon---3gCRo,
.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ-:hover>.sidenav---navitem---9uL5T .sidenav---navtext---1AE_f,
.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u>.sidenav---navitem---9uL5T .sidenav---navicon---3gCRo,
.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u>.sidenav---navitem---9uL5T .sidenav---navtext---1AE_f {
  color: #000000 !important;
}

.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ->.sidenav---navitem---9uL5T .sidenav---navtext---1AE_f {
  color: #8d8d8d !important;
}

/* 
.dropdown-title::after {
  display: none !important;
} */

#navbar-light-example .dropdown-toggle::after {
  display: none !important;
}

#party_master ul button {
  border-radius: 0px !important;
  padding: 18px;
}

#party_master .nav-link {
  color: black !important;
  font-size: clamp(16px, 2vw, 18px);
  font-weight: 600;
}

#party_master .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: white !important;
  background-color: #344454 !important;
}

#party_master .nav-pills {
  border-bottom: 2px solid #f5f5f5;
}

/* button styles */
.button-primary {
  background-color: var(--blue-dark) !important;
  color: var(--white-normal) !important;
  border-color: var(--blue-dark) !important;

  &:hover {
    background-color: var(--blue-dark-hover) !important;
    border-color: var(--blue-dark-hover) !important;
  }

  &:focus {
    box-shadow: 0 0 5px rgba(49, 132, 253, 0.3) !important;
  }

  &:active {
    color: var(--white-normal) !important;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    border-color: var(--blue-dark-active) !important;
    background-color: var(--blue-dark-active);
  }

  &:disabled {
    color: var(--white-normal) !important;
    border-color: var(--grey-normal) !important;
    background-color: var(--grey-normal) !important;
  }
}

.button-danger {
  background-color: var(--pink) !important;
  color: var(--white-normal) !important;
  border-color: var(--pink) !important;

  &:hover {
    background-color: var(--pink-hover) !important;
    border-color: var(--pink-hover) !important;
  }

  &:focus {
    box-shadow: 0 0 5px rgba(49, 132, 253, 0.3) !important;
  }

  &:active {
    color: var(--white-normal) !important;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    border-color: var(--pink-hover) !important;
    background-color: var(--pink-hover);
  }

  &:disabled {
    color: var(--white-normal) !important;
    border-color: var(--grey-normal) !important;
    background-color: var(--grey-normal) !important;
  }
}

.button-secondary {
  background-color: var(--white-normal) !important;
  color: var(--black-normal) !important;
  border-color: var(--black-normal) !important;
  cursor: "pointer";

  &:hover {
    background-color: var(--white-normal) !important;
    border-color: var(--grey-normal) !important;
  }

  &:focus {
    box-shadow: 0 0 5px rgba(49, 132, 253, 0.3) !important;
  }

  &:active {
    color: var(--white-normal) !important;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    border-color: var(--grey-normal) !important;
    background-color: var(--grey-normal) !important;
  }

  &:disabled {
    color: var(--grey-light) !important;
    border-color: var(--grey-light) !important;
    background-color: var(--white-normal) !important;
  }
}

.btn-light-grey {
  background-color: #8d8d8d !important;
  color: var(--white-normal) !important;
  border-color: #8d8d8d;

  &:hover {
    background-color: #7b7979 !important;
    color: var(--white-normal) !important;
    border-color: #7b7979;
  }

  &:focus {
    box-shadow: 0 0 5px rgba(49, 132, 253, 0.3) !important;
  }

  &:active {
    color: var(--white-normal) !important;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    border-color: #7b7979;
    background-color: #7b7979;
  }

  &:disabled {
    color: var(--white-normal) !important;
    border-color: #8d8d8d;
    background-color: #8d8d8d;
  }
}

/* button styles */
.btn-grey {
  background-color: #344454 !important;
  color: var(--white-normal) !important;
  border-color: #344454;

  &:hover {
    background-color: #2f3e4d !important;
    color: var(--white-normal) !important;
    border-color: #2f3e4d;
  }

  &:focus {
    box-shadow: 0 0 5px rgba(49, 132, 253, 0.3) !important;
  }

  &:active {
    color: var(--white-normal) !important;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    border-color: #2f3e4d;
    background-color: #2f3e4d;
  }

  &:disabled {
    color: var(--white-normal) !important;
    border-color: #344454;
    background-color: #344454;
  }
}

.btn-lightblue {
  background-color: #abbccc !important;
  color: #2a3643 !important;
  border-color: #abbccc;

  &:hover {
    background-color: #87a4be !important;
    color: #2a3643 !important;
    border-color: #87a4be;
  }

  &:focus {
    box-shadow: 0 0 5px rgba(49, 132, 253, 0.3) !important;
  }

  &:active {
    color: #2a3643 !important;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    border-color: #87a4be;
    background-color: #87a4be;
  }

  &:disabled {
    color: #2a3643 !important;
    border-color: #abbccc;
    background-color: #abbccc;
  }
}

.btn-darkblue {
  background-color: #4e7dae !important;
  color: var(--white-normal) !important;
  border-color: #4e7dae;

  &:hover {
    background-color: #4778ac !important;
    color: var(--white-normal) !important;
    border-color: #4778ac;
  }

  &:focus {
    box-shadow: 0 0 5px rgba(49, 132, 253, 0.3) !important;
  }

  &:active {
    color: var(--white-normal) !important;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    border-color: #4778ac;
    background-color: #4778ac;
  }

  &:disabled {
    color: var(--white-normal) !important;
    border-color: #4e7dae;
    background-color: #4e7dae;
  }
}

/* button styles */
.btn-yellow {
  background-color: #eeae2d !important;
  color: var(--black-normal) !important;
  border-color: #eeae2d;
  border-radius: 0px;

  &:hover {
    background-color: #dfa32c !important;
    color: var(--black-normal) !important;
    border-color: #dfa32c;
  }

  &:focus {
    box-shadow: 0 0 5px rgba(49, 132, 253, 0.3) !important;
  }

  &:active {
    color: #344454 !important;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    border-color: #dfa32c;
    background-color: #dfa32c;
  }

  &:disabled {
    color: #344454 !important;
    border-color: #eeae2d;
    background-color: #eeae2d;
  }
}

.btn-yellow-wt {
  background-color: #eeae2d !important;
  color: var(--white-normal) !important;
  border-color: #eeae2d;
  border-radius: 0px;

  &:hover {
    background-color: #dfa32c !important;
    color: var(--white-normal) !important;
    border-color: #dfa32c;
  }

  &:focus {
    box-shadow: 0 0 5px rgba(49, 132, 253, 0.3) !important;
  }

  &:active {
    color: #344454 !important;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    border-color: #dfa32c;
    background-color: #dfa32c;
  }

  &:disabled {
    color: #344454 !important;
    border-color: #eeae2d;
    background-color: #eeae2d;
  }
}

input.control-border {
  border: 1px solid #abbccc;
  border-radius: 0px;
  line-height: 24px;
}

textarea.control-border {
  border: 1px solid #abbccc;
  border-radius: 0px;
  line-height: 24px;
}

.lbl-style {
  font-size: 14px;
  color: #344454;
}

.pc_card_title {
  font-size: clamp(16px, 20px, 20px);
}

.items-center {
  align-items: center;
}

.m-0 {
  margin: 0;
}

.ml-2 {
  margin-left: 0.5rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mr-9 {
  margin-right: 9px;
}

::-webkit-input-placeholder {
  color: #8d8d8d;
  font-size: var(--font-size-16);
}

.w-full {
  width: 100%;
}

.calender {
  background: #ffff;
  margin-top: 40px;
  padding: 30px;
}

.calender .week {
  border-bottom: 3px solid #000000;
  margin: 0px 10px;
}

.calender .date {
  border-bottom: 3px solid #000000;
  margin: 10px 10px;
  height: 200px;
}

.editIcon {
  background-color: #2a3643;
  border-radius: 15%;
  display: inline-block;
  padding: 4px 6px;
  cursor: pointer;
}

.editIcon-sm {
  background-color: #2a3643;
  border-radius: 15%;
  display: inline-block;
  padding: 2px 6px;
  cursor: pointer;
}

.deleteIcon {
  background-color: #ff134e;
  border-radius: 15%;
  display: inline-block;
  padding: 4px 6px;
  cursor: pointer;
}

.deleteIcon-sm {
  background-color: #ff134e;
  border-radius: 15%;
  display: inline-block;
  padding: 2px 6px;
  cursor: pointer;
}

.button-blur {
  background-color: var(--grey-normal);
  border-radius: 15%;
  display: inline-block;
  padding: 4px 6px;
  cursor: pointer;
}

.downloadIcon {
  background-color: #eeae2d;
  border-radius: 15%;
  display: inline-block;
  padding: 4px 6px;
  cursor: pointer;
}

.bg-grey {
  background-color: #2a3643 !important;
  color: white !important;
}

.pdrIcon {
  background-color: #abbccc;
  border-radius: 15%;
  display: inline-block;
  padding: 4px 6px;
  cursor: pointer;
}

.spanStyle {
  background: #eeae2d;
  padding: 1px;
}

.cursor_pointer {
  cursor: pointer;
}

.cursor_no-drop {
  cursor: no-drop;
}

.bg-lightgrey {
  background: rgba(198, 198, 199, 0.205);
}

.signin {
  background: #03403a;
  min-height: 100vh;
  position: relative;
}

.signin #title {
  margin-top: 50px;
}

.signin .card {
  border: none;
}

.signin .card .card-header {
  padding: 1.25rem !important;
  color: white;
}

.card_title {
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 12px;
  padding-bottom: 12px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.page-wrapper .page-main-header {
  background-color: #00897b;
}

.page-main-header .main-header-right svg line {
  color: #ffff;
}

.authentication-main .auth-innerright {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-height: 62vh;
}

.button-auth {
  margin: 0 -10px;
}

.authentication-main {
  background: #f6f7fb;
  padding: 15px 100px;
}

.authentication-box {
  width: 500px;
  z-index: 2;
  margin: 0 auto;
}

.authentication-box .theme-form {
  padding-top: 20px;
}

.authentication-box .theme-form .form-group .col-form-label {
  color: #999;
}

.authentication-box .theme-form .social .form-row {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.authentication-box h3 {
  color: #2a3142;
  font-weight: 600;
  letter-spacing: 1px;
}

.authentication-box h4 {
  color: #313131;
  font-weight: 600;
  letter-spacing: 1px;
}

.authentication-box h6 {
  color: #777777;
  letter-spacing: 1px;
  font-size: var(--font-size-14);
}

/* Style for Page Heading */
.page-heading {
  font-size: clamp(var(--font-size-16), 2vw, var(--font-size-32));
  font-family: "roboto-bold", "Arial" !important;
  line-height: 24px;

  @media (max-width: 992px) {
    font-size: clamp(var(--font-size-16), 2vw, var(--font-size-24));
  }

  @media (max-width: 576px) {
    font-size: clamp(var(--font-size-16), 2vw, var(--font-size-16));
  }
}

.label-text {
  font-size: clamp(16px, 2vw, 18px);
  font-weight: 700;
  line-height: 24px;
}

/* Style for Page Heading */

/* Style for tabs pills text */
label.txt-control {
  font-size: clamp(8px, 2vw, 16px);
  font-weight: 500;
  line-height: 24px;
}

.txt-control {
  font-size: clamp(8px, 2vw, 16px);
  font-weight: 500;
  line-height: 24px;
}

.entriesPerPage {
  font-size: var(--font-size-16);
}

/* Style for tabs pills text */

.nc .filter,
input::placeholder,
textarea::placeholder {
  font-size: var(--font-size-14) !important;
}

/* this class is used to add background color to table header */
.table-grey {
  --bs-table-bg: var(--grey-light) !important;
  color: var(--black-normal) !important;
  /* --bs-table-color: #fff;
  --bs-table-bg: #2A3643;
  --bs-table-border-color: #373b3e;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: white; */
  /* border-color: var(--bs-table-border-color); */
}

/* Common table styles applied for all tables */
table thead tr th {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

table thead tr th,
table tbody tr td {
  font-size: var(--font-size-14) !important;
  padding-left: var(--font-size-16) !important;
  vertical-align: middle;
}

.sidenav---sidenav-toggle---1KRjR {
  height: 56px !important;
}

#root_div_main {
  transition: margin-left 0.4s;
  position: relative;
  background: #f5f5f5;
  box-sizing: border-box;
  overflow: hidden;
}

/* below class is used for styling the filter layout sidebar */
.customsidebar {
  height: 100%;
  width: 0;
  max-width: 320px !important;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #abbccc;
  overflow-x: hidden;
  transition: 0.5s ease-in-out;
}

.customsidebar .content {
  overflow-y: auto;
  max-height: 90vh;
  min-height: 90vh;
  margin-top: 40px;
  padding: 18px;
}

.customsidebar #basic-typeahead-single {
  transform: unset !important;
  inset: unset !important;
}

/* .rbt-menu {
  position: absolute !important;
  inset: 0px auto auto 0px !important;
  display: block !important;
  max-height: 300px !important;
  overflow: auto !important;
  transform: translate(0px, 38px) !important;
  width: 288.328px !important;
} */

/* close button for sidebar layout */
.customsidebar .closebtn {
  text-decoration: none;
  font-size: 25px;
  color: #000000;
  display: block;
  transition: 0.3s;
  position: absolute;
  top: 0;
  right: 18px;
  font-size: 36px;
  cursor: pointer;
}

/* .customsidebar .closebtn:hover {
  color: #000000;
} */

.supplier_customsidebar {
  height: 100%;
  width: 0;
  max-width: 320px !important;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #abbccc;
  overflow-x: hidden;
  transition: 0.5s ease-in-out;
}

.supplier_customsidebar .content {
  overflow-y: auto;
  max-height: 90vh;
  min-height: 90vh;
  margin-top: 60px;
}

.machine_customsidebar {
  height: 100%;
  z-index: 1;
  top: 0;
  left: 0;
  transition: 0.5s ease-in-out;
}

.machine_customsidebar .content {
  overflow-y: auto;
  max-height: 100vh;
  min-height: 100vh;
}

.supplier_customsidebar .closebtn {
  text-decoration: none;
  font-size: 25px;
  color: #000000;
  display: block;
  transition: 0.3s;
  position: absolute;
  top: 0;
  right: 18px;
  font-size: 36px;
  cursor: pointer;
}

.custom-close-btn {
  text-decoration: none;
  font-size: 24px;
  color: #000000;
  transition: 0.3s;
  cursor: pointer;
}

/* datepicker dropdown doesnt properly display inside sidebar for handling that below styles are required */
#root_div_main .fromDate .react-datepicker-popper {
  position: absolute;
  inset: unset !important;
  transform: unset !important;
  z-index: 9999 !important;
}

#root_div_main .fromDate .react-datepicker__triangle {
  position: absolute !important;
  left: 15% !important;
  transform: translate(0px, 0px) !important;
}

#root_div_main .toDate .react-datepicker-popper {
  position: absolute;
  inset: unset !important;
  transform: unset !important;
  z-index: 9999 !important;
}

#root_div_main .toDate .react-datepicker__triangle {
  position: absolute !important;
  left: 15% !important;
  transform: translate(0px, 0px) !important;
}

.nc_detail_label {
  color: var(--grey-dark);
  font-size: var(--font-size-14);
}

.form_label {
  color: var(--black-normal);
  font-size: var(--font-size-14);
}

.react-datepicker-wrapper {
  width: 100%;
}

.err-msg {
  font-size: var(--font-size-14);
}

.prod_container {
  display: flex;
  justify-content: center;
  /* Center the child elements horizontally */
}

/* Child divs */
.prod_box {
  padding: 6px 10px;
  border: 1px solid #c6c6c6;
  margin: 6px;
  cursor: pointer;
  color: var(--black-normal);
  letter-spacing: 0.5px;
}

.prod_box.active {
  background-color: #344454;
  border: none;
  color: white;
}

.prod_box span {
  font-size: 14px;
}

/* Optional: Apply responsive styles for smaller screens */
@media (max-width: 768px) {
  .prod_container {
    flex-wrap: wrap;
    /* Allow wrapping of child elements on smaller screens */
  }
}

/* Navbar styles */
#main_link_nav .nav-link {
  font-family: "roboto-bold", "Arial" !important;
  letter-spacing: 0.5px;
  color: #2a3643;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}

/* making selected link active in navbar */
.navbar-nav .nav-link.active {
  color: var(--red-normal) !important;
  border-bottom: 3px solid var(--red-normal);
}

#main_link_nav_two .nav-link {
  font-family: "roboto-bold", "Arial" !important;
  letter-spacing: 0.5px;
  color: #2a3643;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}

.master_screen #ccard .card .card-title {
  font-family: "roboto-bold", "Arial" !important;
  color: #2a3643;
  font-size: var(--font-size-16) !important;
  display: inline-block;
  border-bottom: 2px solid #fff;
}

.master_screen #ccard .card:hover .card-title {
  color: var(--red-normal) !important;
  display: inline-block;
  border-bottom: 2px solid var(--red-normal);
}

.product .dropdown-menu {
  background-color: #dde1e6 !important;
}

.no-decor {
  text-decoration: none;
}

/* reducing border radius of all input type in project */
.form-control-sm {
  border-radius: 2px !important;
}

.form-control {
  border-radius: 2px !important;
}

/* styles used for pagination */
.pagination .active>.page-link {
  background-color: var(--blue-dark) !important;
  border-color: var(--blue-dark) !important;
  color: var(--white-normal) !important;
}

.pagination .page-link {
  color: var(--blue-dark) !important;
}

/* modal close button image */
.btn-close {
  --bs-btn-close-bg: url("./assets/images/modalCross1.png") !important;
}

.image_gallery .image-container {
  position: relative;

  /* overflow: hidden; */
}

.image_gallery img {
  border-radius: 8px;
  height: 120px !important;
  width: 100%;
}

.image_gallery .image-overlay {
  position: absolute;
  border-radius: 8px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image_gallery .image-container:hover .image-overlay {
  opacity: 1;
}

.image_gallery .icon {
  color: #fff;
  cursor: pointer;
  /* font-size: 1.5rem; */
}

.image_gallery .icon-close {
  background: var(--red-normal);
  cursor: pointer;
  height: 14px;
  width: 14px;
  border-radius: 2px;
  padding: 3px;
  position: absolute;
  top: 1%;
  left: 99%;
  transform: translate(-50%, -50%);
  z-index: 99;
}

.image_gallery .image-name {
  color: white;
  font-size: 12px;
  position: absolute;
  bottom: 5%;
  line-height: 1;
  left: 50%;
  transform: translate(-50%, 0%);
  z-index: 99;
}

.image_gallery2 .image-container {
  position: relative;

  /* overflow: hidden; */
}

.image_gallery2 img {
  border-radius: 8px;
  height: 140px !important;
  width: 90%;
  max-width: 300px;
  margin: 10px;
}

.image_gallery2 .image-overlay {
  position: absolute;
  border-radius: 8px;
  top: 0;
  left: 0;
  width: 90%;
  max-width: 300px;
  margin: 10px;
  height: 140px;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image_gallery2 .image-container:hover .image-overlay {
  opacity: 1;
}

.image_gallery2 .icon {
  color: #fff;
  cursor: pointer;
  /* font-size: 1.5rem; */
}

.image_gallery2 .icon-close {
  background: var(--red-normal);
  cursor: pointer;
  height: 14px;
  width: 14px;
  border-radius: 2px;
  padding: 3px;
  position: absolute;
  top: 1%;
  left: 99%;
  transform: translate(-50%, -50%);
  z-index: 99;
}

.image_gallery2 .image-name {
  color: white;
  font-size: 12px;
  position: absolute;
  bottom: 5%;
  line-height: 1;
  left: 50%;
  transform: translate(-50%, 0%);
  z-index: 99;
}

.drawing_gallery .image-container {
  position: relative;

  /* overflow: hidden; */
}

.drawing_gallery img {
  border-radius: 8px;
  height: 120px !important;
  width: 100%;
}

.drawing_gallery .other_doc {
  border-radius: 8px;
  height: 120px !important;
  width: 100%;
  background: #abbccc;
}

.drawing_gallery .image-container .icon-close {
  background: var(--red-normal);
  cursor: pointer;
  height: 14px;
  width: 14px;
  border-radius: 2px;
  padding: 3px;
  position: absolute;
  top: 1%;
  left: 99%;
  transform: translate(-50%, -50%);
  z-index: 99;
}

.drawing_gallery .image-name {
  font-size: 14px;
}

.slick-prev:before {
  color: var(--red-normal) !important;
}

.slick-next:before {
  color: var(--red-normal) !important;
}

.pc .accordion-button {
  padding: 12px 18px !important;
  font-size: clamp(15px, 18px, 18px) !important;
  background: #4e7dae !important;
  font-family: "inter-bold", sans-serif !important;
  color: white !important;
}

.pc .accordion-button:not(.collapsed)::after {
  background-image: url("./assets/images/acxordionArr.svg") !important;
}

.pc .accordion-button::after {
  background-image: url("./assets/images/accordionArr2.svg") !important;
}

.pc .accordion-body {
  background: white;
}

.searchWrapper {
  background: white;
}

.chip {
  background: #e7f4ff !important;
  color: #007bff !important;
  border-radius: 4px !important;
  font-size: 14px !important;
  padding: 0px 8px 0px 8px !important;
}

.multiSelectContainer input {
  margin-top: 0px !important;
}

.custom-close {
  cursor: pointer;
}

.input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-top-right-radius: 2px !important;
  border-bottom-right-radius: 2px !important;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  background-color: var(--red-normal) !important;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-cancel {
  background-color: #2a3643 !important;
}

.landing_page_title {
  margin-bottom: 0px !important;
  font-size: clamp(36px, 2vw, 34px);
  color: #2a3643;
}

.landing_page_tag_line {
  max-width: 900px;
  padding: 0px 40px;
  color: #344454;
  font-size: 30px;
}

.landing_page_nav a {
  color: #8d8d8d !important;
}

.landing_page {
  overflow-x: hidden;
}

.login_page {
  overflow-x: hidden;
  /* padding: 20px; */
  height: 100vh;
}

.div_center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.text_gold {
  color: #eeae2d;
}

.form_div {
  margin-top: 100px;
}

.login_page #login_img {
  height: 95vh;
}

.pink_rectangle {
  height: 6px;
  width: 130px;
  background: #ff134e;
}

.text_over_img {
  top: 10%;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 400px;
  color: white;
  position: absolute;
  font-size: 1.4em;
}

.text_over_img1 {
  padding-left: 20px;
  padding-right: 20px;
  font-size: 1.1em;
}

@media (max-width: 768px) {

  /* Styles for smaller screens */
  .login_page {
    overflow-x: hidden;
    height: 100%;
  }

  .form_div {
    margin-top: 10px;
  }

  .login_page #login_img {
    height: 100%;
    margin-top: 15px;
  }

  .text_over_img {
    top: 10%;
    padding-left: 10px;
    padding-right: 10px;
    /* transform: translate(-50%, -50%); */
    max-width: 400px;
    color: white;
    position: absolute;
    font-size: 1.4em;
  }
}

/* circle pecentage */
.chart {
  width: 100px;
  height: 100px;
}

.base-circle {
  fill: none;
  stroke: #e0e0e0;
  stroke-width: 3;
  stroke-dasharray: 100;
  transition: stroke-dasharray 0.5s ease-in-out;
}

.circle-fill {
  fill: none;
  stroke-width: 3;
  stroke-linecap: round;
  transform: rotate(-90deg);
  transform-origin: center;
  transition: stroke-dasharray 0.5s ease-in-out;
}

.percentage {
  text-align: center;
  margin-top: 8px;
  font-size: 18px;
  font-weight: bold;
}

.sm_circle {
  height: 20px;
  width: 20px;
  border-radius: 50%;
}

.bg-pink {
  background: #ff134e;
}

.bg-yellow {
  background: #eeae2d;
}

.nc_db_stat_label {
  color: #344454;
  font-size: 18px;
}

.nc_db_stat_value {
  font-size: clamp(16px, 40px, 40px);
}

.nc_db_per_content {
  color: #2a3643;
}

/* .pc table thead th {
  background: none;
  border: none;
  /* padding: 8px !important;
  border-bottom: 6px solid #f3f2f2;
} */

.pc table .collapsible-row td {
  padding: 0px !important;
  /* border: 3px solid #f3f2f2; */
}

.pc .collapsible-row .collapse-content {
  display: none;
  padding: 15px;
  background-color: #abbccc;
  max-height: 0;
  overflow: hidden;
  transition: max-height 2s ease-out;
  color: #344454;
}

.pc .collapsible-row.expanded .collapse-content {
  display: block;
  width: 100%;
  max-height: 500px;
}

.collapsible-row .label {
  font-size: 15px;
}

.steps span {
  opacity: 0.3;
}

.steps .active {
  opacity: 1 !important;
}

.prod_configurator .dropdown-menu {
  width: 250px;
}

.pill-container {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  /* Adjust the gap between pills as needed */
}

.custom-pill {
  background-color: #abbccc;
  color: #344454;
  border-radius: 2px;
  padding: 5px 10px;
  margin-right: 5px;
  font-size: 15px;
  /* Adjust the margin between pills as needed */
  cursor: pointer;
  user-select: none;
}

.supplier_customsidebar .supplier_box {
  background: #d3dae0;
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
  cursor: pointer;
  font-size: 14px;
  padding: 10px 10px 7px 10px;
}

.supplier_customsidebar .supplier_box:hover {
  background: #abbccc;
}

.supplier_360_view .accordion {
  margin: 20px;
}

.supplier_360_view .accordion-item {
  /* border-top: none;
  border-right: none;
  border-left: none; */
  border-radius: 0;
  outline: none;
}

.supplier_360_view .accordion button:focus {
  outline: 0 !important;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}

.accordion-button:not(.collapsed) {
  background: #fff !important;
}

.supplier_360_view .accordion-header button {
  padding: 10px;
  font-size: 16px;
}

.supplier_360_view .div_one {
  background: white;
  /* border-right: 2px solid rgb(198, 198, 198); */
  font-size: 14px;
  padding: 20px 20px 20px 30px;
}

.supplier_360_view .label {
  color: #344454;
}

.prod_bom_accor .accordion-button {
  background-color: #8d8d8d !important;
  color: white !important;
}

.prod_bom_accor .accordion-body {
  background-color: #fdfbfb !important;
}

/* .accordion {
  --bs-accordion-btn-icon: url("./assets/images/acxordionArr.svg") !important;
  --bs-accordion-btn-active-icon: url("./assets/images/acxordionArr.svg") !important;
} */

.PO_detail_modal table thead th {
  background: #2a3643 !important;
  color: #fff !important;
  font-family: "inter-bold", sans-serif !important;
  font-weight: "600" !important;
  text-align: center;
}

.no-badge-weight {
  --bs-badge-font-weight: 0 !important;
}

.PO_Details .accordion-button {
  background-color: #8d8d8d !important;
  color: white !important;
  padding: 10px !important;
}

.po_line {
  height: 2px;
  background: #abbccc;
  width: 100%;
}

.pi_data {
  color: #667080;
  font-size: 18px;
}

.inventory_customsidebar {
  height: 100%;
  width: 0;
  /* max-width: 320px !important; */
  overflow-x: auto;
  white-space: nowrap;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #ffffff;
  transition: 0.5s ease-in-out;
}

.inventory_customsidebar #content {
  width: 320px;
  overflow-x: auto !important;
  max-height: 78vh;
  min-height: 78vh;
  margin-top: 60px;
}

.inventory_customsidebar .closebtn {
  text-decoration: none;
  font-size: 25px;
  color: #000000;
  display: block;
  transition: 0.3s;
  position: absolute;
  top: 0;
  right: 18px;
  font-size: 36px;
  cursor: pointer;
}

.child {
  display: none;
  list-style-type: none;
  padding-left: 20px;
}

input[type="checkbox"]:checked+ul.child {
  display: block;
}

/*  */

:root {
  --line-color: #666;
  --line-width: 0.1em;
  --gutter: 2em;
}

.org_hierarychy .company_details {
  --bs-gutter-x: unset !important;
  --bs-gutter-y: unset !important;
  /* display: unset !important; */
}

.org_hierarychy .card-body {
  overflow-y: auto !important;
}

.org_hierarychy .tree .card {
  max-width: 300px;
  min-width: 300px;
  background: red;
}

.org_hierarychy .tree {
  margin: 0 0 calc(var(--gutter) * 2);
  text-align: center;
}

.org_hierarychy .tree,
.org_hierarychy .tree ul,
.org_hierarychy .tree li {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
}

.org_hierarychy .tree,
.org_hierarychy .tree ul {
  display: table;
}

.org_hierarychy .tree ul {
  width: 100%;
}

.org_hierarychy .tree li {
  display: table-cell;
  padding: var(--gutter) 0;
  vertical-align: top;
}

.org_hierarychy .tree li:before {
  content: "";
  left: 0;
  outline: solid calc(var(--line-width) / 2) var(--line-color);
  position: absolute;
  right: 0;
  top: 0;
}

.org_hierarychy .tree li:first-child:before {
  left: 50%;
}

.org_hierarychy .tree li:last-child:before {
  right: 50%;
}

.org_hierarychy .tree .card {
  border-radius: 0.2em;
  margin: 0 calc(var(--gutter) / 2) var(--gutter);
  min-height: 2.1em;
  position: relative;
  z-index: 1;
}

.org_hierarychy .tree [contenteditable] {
  cursor: text;
}

.org_hierarychy .tree ul:before,
.org_hierarychy .tree .card:before {
  outline: solid calc(var(--line-width) / 2) var(--line-color);
  content: "";
  height: var(--gutter);
  left: 50%;
  position: absolute;
  top: calc(calc(-1 * var(--gutter)) - calc(var(--line-width) / 2));
}

.org_hierarychy .tree>li {
  margin-top: 0;
}

.org_hierarychy .tree>li:before,
.org_hierarychy .tree>li:after,
.org_hierarychy .tree>li>.card:before {
  outline: none;
}

.org_hierarychy .tree .card {
  -webkit-appearance: none;
  -moz-appearance: none;
  /* appearance: none; */
  background: #fff;
  border: solid var(--line-width) var(--line-color);
  cursor: pointer;
  font-size: 1em;
  /* line-height: 1.2em; */
  /* padding: 0.4em 1em; */
  position: relative;
}

/* .org_hierarychy .tree .card:focus,
.org_hierarychy .tree .card:hover {
  outline: 0.1em dotted var(--line-color);
  outline-offset: -0.5em;
} */

.org_hierarychy input[type="range"] {
  display: block;
  width: 100%;
}

.org_hierarychy input[type="color"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  display: block;
  height: 2em;
  padding: 0;
  vertical-align: middle;
  width: 100%;
}

.org_hierarychy .js-confirm .confirm,
.org_hierarychy .js-root .root {
  opacity: 1;
  transform: scale(1, 1);
}

::-webkit-scrollbar {
  width: 10px !important;
  height: 10px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: #ebebeb !important;
  -webkit-border-radius: 10px !important;
  border-radius: 10px !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c0c0c0 !important;
  -webkit-border-radius: 10px !important;
  border-radius: 10px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(168, 168, 168) !important;
}

.createIcon_custom {
  background-color: #4e7dae;
  cursor: pointer;
  height: 22px;
  width: 22px;
  border-radius: 6px;
  /* border-radius: 50%; */
}

.editIcon_custom {
  background-color: #eeae2d;
  cursor: pointer;
  height: 22px;
  width: 22px;
  border-radius: 6px;
  /* border-radius: 50%; */
}

.settignIcon_custom {
  background-color: #abbccc;
  cursor: pointer;
  height: 22px;
  width: 22px;
  border-radius: 6px;
  /* border-radius: 50%; */
}

.settignIcon_custom_nc {
  background-color: #abbccc;
  cursor: pointer;
  height: 28px;
  width: 28px;
  border-radius: 6px;
  /* border-radius: 50%; */
}

.deleteIcon_custom {
  background-color: #2a3643;
  cursor: pointer;
  height: 22px;
  width: 22px;
  border-radius: 6px;
  /* border-radius: 50%; */
}

.org_hierarychy .make_active {
  background: #abbccc !important;
  color: #344454 !important;
}

.nc_dashboard .dropdown .dropdown-menu {
  font-size: 14px !important;
}

.internal_com .content {
  overflow-y: auto;
  max-height: 89vh;
  min-height: 89vh;
  margin-top: 15px;
  padding: 0px;
}

.active-org {
  color: #ff134e;
}

.badge-outline {
  background-color: #ffffff;
  border: 1px solid #000000;
  color: #000000;
}

/* Customer 360 view revamp tab menu */

#cust_tab_master ul button {
  border-radius: 0px !important;
  padding: 12px;
}

#cust_tab_master .nav-link {
  color: black !important;
  font-size: clamp(8px, 2vw, 14px);
  font-weight: 600;
  background-color: #d3d3d3;
  margin-left: 5px;
  margin-right: 5px;
}

#cust_tab_master .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #344454 !important;
  background-color: white !important;
  border-top: 2px solid #2a3643;
}

#cust_tab_master .nav-pills {
  border-bottom: 2px solid #f5f5f5;
}

/* Employee master tab menu */
#emp_tab_master ul button {
  border-radius: 0px !important;
  padding: 12px;
}

#emp_tab_master .nav-link {
  color: black !important;
  font-size: clamp(14px, 2vw, 14px);
  background-color: #d3d3d3;
  margin-left: 5px;
  margin-right: 5px;
}

#emp_tab_master .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #344454 !important;
  background-color: #a0c5ed !important;
  border-top: 2px solid #2a3643;
}

#emp_tab_master .nav-pills {
  border-bottom: 2px solid #f5f5f5;
}

.emp_drawing_gallery .image-container {
  position: relative;
}

.emp_drawing_gallery #org_img {
  border-radius: 8px;
  height: 140px !important;
  width: 100%;
  border: 1px solid #e0e1dd;
}

.emp_drawing_gallery .other_doc {
  border-radius: 8px;
  height: 140px !important;
  width: 100%;
  background: #fff;
  border: 1px solid #e0e1dd;
}

.emp_drawing_gallery .image-container .icon-close {
  background: var(--red-normal);
  cursor: pointer;
  /* font-size: 20px !important; */
  height: 14px;
  width: 14px;
  border-radius: 2px;
  padding: 3px;
  position: absolute;
  top: 1%;
  left: 99%;
  transform: translate(-50%, -50%);
  z-index: 99;
}

.emp_drawing_gallery .image-name {
  font-size: 14px;
}

.kanban-container {
  overflow-x: auto;
  display: flex;
  flex-wrap: wrap;
  /* white-space: nowrap; */
}

.kanban-board {
  display: inline-block;
  width: 300px;
  /* Set the width of each Kanban board */
  /* margin-right: 10px; */
  background-color: var(--white-normal);
  overflow-y: auto;
  max-height: 70vh;
}

#float_header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #fafafa;
  z-index: 222;
}

.link-camp-pill-container span {
  font-size: 12px;
}

.link-camp-pill-container .card {
  padding: 10px 15px;
  line-height: 1.3;
}

.link-camp-pill-container p {
  font-size: 13px;
}

.link-camp-pill-container .pill {
  background: #edede9;
  border: 1px solid #adb5bd;
  min-width: 180px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.link-camp-pill-container {
  position: relative;
}

.link-camp-pill-container .icon_div {
  position: absolute;
  cursor: pointer;
  right: -4px;
  top: -10px;
  background: #ff595e;
  height: 21px;
  width: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}

.link-camp-pill-container .edit_icon_div {
  position: absolute;
  cursor: pointer;
  right: 22px;
  top: -10px;
  background: #2a3643;
  height: 21px;
  width: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}

.inter-bold.cursor_pointer.mb-2:hover {
  color: #4e7dae;
  text-decoration: underline;
}

h1,
h2,
h3,
h4,
h5,
h6,
body {
  font-family: "roboto", Arial !important;
}

.bold {
  font-weight: var(--font-weight-bold) !important;
}

.megaheading1 {
  font-size: var(--font-size-48) !important;
}

.megaheading2 {
  font-size: var(--font-size-40) !important;
}

h1 {
  font-size: var(--font-size-32) !important;
}

h2 {
  font-size: var(--font-size-30) !important;
}

h3 {
  font-size: var(--font-size-24) !important;
}

h4 {
  font-size: var(--font-size-20) !important;
}

.body1 {
  font-size: var(--font-size-16) !important;
}

.body2 {
  font-size: var(--font-size-14) !important;
}

.body3 {
  font-size: var(--font-size-12) !important;
}

.caption {
  font-size: var(--font-size-10) !important;
}

#machine_dashboard_card_header {
  background-color: var(--blue-dark-active) !important;
  padding: 12px 16px 12px 16px;
  letter-spacing: 0.6px;
  border: none;
  color: var(--white-normal);
}

.machine_dashboard .card-body {
  background-color: var(--blue-light);
}

.opacity_low {
  opacity: 0.6;
}

.rounded-indicators {
  li {
    border-radius: 50%;
    width: 10px;
    height: 10px;
    background-color: #007bff;

    &.active {
      background-color: #007bff;
    }
  }
}

.shadow-dark {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.3);
  /* Example dark shadow */
}

#main_link_nav .dropdown-menu {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  font-size: var(--font-size-14) !important;
}

#main_link_nav .dropdown-divider {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  opacity: 0.7 !important;
}

#main_link_nav .dropdown-item {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

#main_link_nav .dropdown-item:hover {
  background: var(--grey-light-active) !important;
  color: var(--black-normal) !important;
}

.nav_icon_color {
  filter: invert(29%) sepia(66%) saturate(2361%) hue-rotate(177deg) brightness(98%) contrast(95%);
}

.svg-img {
  filter: invert(33%) sepia(78%) saturate(1560%) hue-rotate(176deg) brightness(80%) contrast(95%);
}

.icon-danger {
  filter: invert(17%) sepia(92%) saturate(7496%) hue-rotate(353deg) brightness(105%) contrast(104%);
}

.menu_icon_grey {
  color: var(--grey-normal-active);
}

.menu_icon_red {
  color: var(--red-normal);
}

/* indicators */
.ind_green {
  /* color: var(--green-normal); */
  color: #28A745;
}

.ind_green_dark {
  color: #218838;
}

.ind_yellow {
  color: #FFC107;
}

.ind_orange {
  /* color: var(--orange-normal); */
  color: #FD7E14;
}

.ind_red {
  /* color: var(--red-normal); */
  color: #DC3545;
}

.ind_blue {
  /* color: var(--blue-dark); */
  color: #007BFF;
}

.ind_grey {
  color: var(--grey-normal);
}

.ind_sea_green {
  color: #398F90

  ;
}

/*  */

.view_production_data .timeline {
  position: relative;
  /* margin: 50px auto; */
  /* padding: 40px 0; */
  width: auto;
  box-sizing: border-box;
  max-height: 80vh;
  overflow-y: auto;
  /* background: rebeccapurple; */
}

.view_production_data .timeline .card {
  border-radius: 20px;
  background: #f2f1ebc2;
  border: none;
}

.view_production_data .timeline .active {
  background: var(--blue-dark);
  /* background: var(--blue-normal); */
  border: none;
  color: white;
  transition: 0.3s ease-in-out;
  transition-timing-function: linear;
}

.prod_data_label {
  color: var(--grey-dark);
  font-size: var(--font-size-14);
  letter-spacing: 0.6px;
}

.card.active .prod_data_label {
  color: var(--grey-light);
  font-size: var(--font-size-14);
  transition: 0.3s ease-in-out;
  transition-timing-function: linear;
}

.view_production_data .timeline .card:hover {
  /* transition: 0.5s ease-out;
  background: var(--blue-light-hover);
  color: white; */
  cursor: pointer;
}

.view_production_data .timeline:before {
  content: "";
  position: absolute;
  left: 5%;
  width: 2px;
  height: 100%;
  background: #c5c5c5;
}

.view_production_data .timeline ul {
  padding: 0;
  margin: 0;
}

.view_production_data .timeline ul li {
  list-style: none;
  position: relative;
  width: 95%;
  padding: 15px 30px;
  box-sizing: border-box;
}

.view_production_data .timeline ul li {
  float: right;
  text-align: left;
  clear: both;
}

.view_production_data .timeline ul li:before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  top: 24px;
  left: -4px;
  background: var(--blue-dark);
  border-radius: 50%;
  box-shadow: 0 0 0 3px rgba(33, 166, 233, 0.2);
}

.view_production_data .timeline ul li p {
  margin: 10px 0 0;
  padding: 0;
}

.view_production_data .selected_prod_data {
  background: #caf0f8;
  border-radius: 20px;
}

.view_production_data #total_prod_qty {
  font-size: var(--font-size-20);
}

.view_production_data #total_prod_qty span:nth-child(2) {
  color: var(--blue-dark);
  font-size: var(--font-size-24);
}

.slide-up {
  animation: slide-up 1s forwards;
}

#tab_border .nav-tabs .nav-link {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
}

@keyframes slide-up {
  from {
    transform: translateY(80%);
    opacity: 0;
    scale: 0.5;
  }

  to {
    transform: translateY(0%);
    opacity: 1;
    scale: 1;
  }
}

@media (max-width: 1000px) {
  .view_production_data .timeline {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .view_production_data .timeline {
    width: 100%;
    padding-bottom: 0;
  }

  .view_production_data h1 {
    font-size: 40px;
    text-align: center;
  }

  .view_production_data .timeline:before {
    left: 20px;
    height: 100%;
  }

  .view_production_data .timeline ul li {
    width: 100%;
    text-align: left;
    padding-left: 50px;
    padding-bottom: 50px;
  }

  .view_production_data .timeline ul li:before {
    top: -18px;
    left: 16px;
  }
}

#product_configurator_card .nav-tabs .nav-link {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
}

#product_configurator_card .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background: none !important;
  color: var(--blue-dark) !important;
  font-family: "roboto-bold", "Arial" !important;
  font-weight: var(--font-weight-bold) !important;
  border-bottom: 3px solid var(--blue-dark) !important;
  transition: width 1s ease-in-out;
}

#product_configurator_card .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link {
  color: var(--grey-dark) !important;
}

#product_configurator_card .nav-tabs {
  border-bottom: 2px solid rgba(128, 128, 128, 0.237) !important;
}

.selected_prod_data {
  background: #f9f9f9;
  border-radius: 20px;
}

/* production plannign steppers */

#production_planning .stepper-container {
  width: 100%;
  margin: 15px auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

#production_planning #bom_id {
  font-size: 14px;
}

/* .stepFour #row1 {
  border: 2px solid var(--grey-dark);
  border-radius: 20px;
  padding: 20px;
} */

#production_planning .step {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
}

#production_planning .step .circle {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin-bottom: 6px;
  font-size: 16px;
}

#production_planning .circle.completed {
  background-color: #4caf50;
}

#production_planning .label {
  font-size: 16px;
  text-align: center;
}

#production_planning .line {
  position: absolute;
  top: 25%;
  left: 82%;
  transform: translate(-25%, -50%);
  width: 130px;
  height: 2px;
  background-color: #ccc;
}

#production_planning .step:not(:last-child)::after {
  content: "";
  position: absolute;
  top: 15px;
  left: 50%;
  width: 100%;
  height: 2px;
  background-color: #ccc;
  z-index: -1;
}

#production_planning .step.completed:not(:last-child)::after {
  background-color: #4caf50;
}

@media (max-width: 600px) {
  #production_planning .stepper-container {
    flex-direction: column;
  }

  #production_planning .step:not(:last-child)::after {
    width: 2px;
    height: 50px;
    top: 50%;
    left: 15px;
  }
}

@media (max-width: 600px) {
  #production_planning .stepper-container {
    flex-direction: column;
  }

  #production_planning .step {
    margin-bottom: 20px;
  }

  #production_planning .line {
    width: 2px;
    height: 50px;
    top: 100%;
    left: 15px;
  }
}

#production_planning .nav-tabs .nav-link {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
}

#pro_plan_label {
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-bold);
  color: var(--blue-dark);
  letter-spacing: 0.5px;
}

.refresh-icon {
  height: 32px;
  width: auto;
  color: #495057;
  border: 2px solid #495057;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

#refresh-icon-img {
  height: 22px;
  width: 22px;
  /* display: inline-block; */
}

.spin {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Card background color */
.bg-orange-light {
  background: var(--orange-light-hover);
}

.bg-orange-dark {
  background: var(--orange-light-active);
}

.bg-green-light {
  background: var(--green-light-hover);
}

.bg-red-light {
  background: var(--red-light-active);
}

.stepFive .row .key {
  font-size: var(--font-size-14);
  color: var(--grey-dark);
}

.stepFive .row .value {
  font-size: var(--font-size-16);
  font-weight: 600;
}

.stepFive .row {
  padding: 20px;
  border-radius: 20px;
  margin: 20px 0px;
  border: 1px solid var(--grey-light-active);
}

.stepFive .accordion button {
  background: #d1e9f6 !important;
  font-size: var(--font-size-16);
  letter-spacing: 0.6px;
  /* color: white  ; */
}

.stepFive h6 {
  font-weight: 600;
  color: var(--grey-darker);
}

.StepOne .card {
  border: 1px solid #e7e8d8;
}

.typeahead-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.typeahead-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
}

.arrow-icon {
  position: absolute;
  right: 5px;
  top: 75%;
  transform: translateY(-50%) rotate(0deg);
  width: 18px;
  height: 18px;
  pointer-events: none;
  transition: transform 0.3s ease;
  background-image: url("./assets/images/icons/project_menu/down-chevron.png");
  /* Replace with your down arrow image */
  background-size: contain;
  background-repeat: no-repeat;
}

.arrow-icon-2 {
  position: absolute;
  right: 5px;
  top: 54%;
  transform: translateY(-50%) rotate(0deg);
  width: 18px;
  height: 18px;
  pointer-events: none;
  transition: transform 0.3s ease;
  background-image: url("./assets/images/icons/project_menu/down-chevron.png");
  /* Replace with your down arrow image */
  background-size: contain;
  background-repeat: no-repeat;
}

.typeahead-container:focus-within .arrow-icon {
  transform: translateY(-50%) rotate(180deg);
  /* transition: .3s ease-in; */
  /* Rotate to make it look like an up arrow */
  background-image: url("./assets/images/icons/project_menu/down-chevron.png");
}

.typeahead-container:focus-within .arrow-icon-2 {
  transform: translateY(-50%) rotate(180deg);
  /* transition: .3s ease-in; */
  /* Rotate to make it look like an up arrow */
  background-image: url("./assets/images/icons/project_menu/down-chevron.png");
}

.stepFour #row1 .product_container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 400px;
  /* Adjust height according to your needs */
  width: 100%;
  /* It will take up the full width */
  gap: 20px;
}

.stepFour #row1 .product_container .product_name {
  color: var(--grey-dark);
  letter-spacing: 0.5px;
}

.stepFour #row1 .product_container .bom_id {
  color: var(--black-normal);
  letter-spacing: 0.5px;
}

.stepFour #row1 .product_container .item.active .product_name {
  color: var(--white-normal);
  letter-spacing: 0.5px;
}

.stepFour #row1 .product_container .item.active .bom_id {
  color: var(--white-normal);
  letter-spacing: 0.5px;
}

.stepFour #row1 .card {
  border-radius: 16px;
  border: 1px solid #e7e8d8;
}

.stepFour #row1 .item {
  color: black;
  padding: 10px;

  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.stepFour #row1 .item.active {
  background-color: var(--blue-dark);
  color: white;
}

/* Media Queries for Responsive Behavior */
@media (min-width: 768px) {
  .stepFour #row1 .product_container {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .stepFour #row1 .item {
    flex-basis: 48%;
    /* 2 items per row for tablet-sized screens */
  }
}

@media (min-width: 1024px) {
  .stepFour #row1 .item {
    flex-basis: 20%;
    /* 3 items per row for larger screens */
  }
}

#fallback_404 {
  display: flex;
  align-items: center;
  justify-content: center;
}

#note_card {
  color: grey;
  border: 1px solid #e7e8d8;
}

#note_card ol {
  padding-left: 20px !important;
  margin-bottom: 0px !important;
}

#note_card li {
  font-size: 15px;
  letter-spacing: 0.5px;
}

#note_card li:not(:last-child) {
  margin-bottom: 5px !important;
}

#open-tooltip {
  z-index: 999 !important;
}

#show-tooltip {
  z-index: 999 !important;
}

#work_order_status_count .card {
  position: relative;
  padding: 10px;
  border-radius: var(--font-size-16);
  border: 1px solid #e7e8d8;
  height: 100px;
  background: #f8f9fa;
}

#work_order_status_count .card p {
  font-size: var(--font-size-16);
  letter-spacing: 1px;
  font-weight: 600;
}

#work_order_status_count img {
  height: 42px;
  width: 42px;
  opacity: 0.3;
  margin-left: -5px;
}

#work_order_status_count .card #count_div p {
  color: var(--white-normal);
}

#work_order_status_count #count_div {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  height: 60px;
  width: 60px;
  background: var(--blue-normal);
  /* border-radius: var(--font-size-16); */
  border-top-left-radius: 23px;
  border-bottom-left-radius: 35px;
  border-bottom-right-radius: 23px;
}

.mt-30 {
  margin-top: 0;
}

#mt-20 {
  margin-top: 0;
}

.pd-30 {
  padding-top: 0;
}

/* .wt-50{
  width: 50%;
} */

/* Media Query for Mobile Devices */
@media (max-width: 480px) {
  .mt-30 {
    margin-top: 30%;
  }

  .wt-50 {
    width: 75%;
  }
}

/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 481px) and (max-width: 767px) {
  .mt-30 {
    margin-top: 30%;
  }

  .wt-50 {
    width: 75%;
  }
}

/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 768px) and (max-width: 1024px) {
  .pd-30 {
    padding-top: 30%;
    padding-left: 3rem !important;
  }

  .mt-30 {
    margin-top: 30%;
  }

  #mt-20 {
    margin-top: 20%;
  }

  .wt-50 {
    width: 75%;
  }
}

/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) and (max-width: 1280px) {
  .wt-50 {
    width: 50%;
  }
}

/* Media Query for Large screens */
@media (min-width: 1281px) {
  .wt-50 {
    width: 50%;
  }
}

#create_workorder_link {
  color: blue;
  cursor: pointer;
  font-size: var(--font-size-14);
}

.ScreenAccess #module_name {
  font-size: var(--font-size-18);
  letter-spacing: 0.6px;
  font-weight: var(--font-weight-bold600);
  text-decoration: none;
  list-style: none;
}

.ScreenAccess .table p {
  font-size: var(--font-size-16);
  color: #8b7e74;
}

.ScreenAccess #search_input {
  border-radius: 20px !important;
}

.ScreenAccess .table label {
  font-size: var(--font-size-16);
  color: #8b7e74;
}

.ScreenAccess .table label:not(:last-child) {
  margin-right: 15px;
}

.form-check-input {
  border: 1px solid #919191 !important;
}

/* .ScreenAccess .permission label {
  font-size: var(--font-size-16);  
} */

#onboarding .card_parent {
  position: relative;
  padding: 10px;
}

#onboarding .card {
  min-height: 100px;
  min-width: 100px;
}

#onboarding .card_parent img {
  position: absolute;
  top: 50px;
  right: -28px;
}

.blue_card {
  background: var(--blue-dark) !important;
}


.access_denied .row {
  height: 75vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.access_denied h4 {
  /* color: var(--blue-dark); */
  color: var(--red-normal);
  font-weight: 600;
  letter-spacing: 0.6px;
}

.access_denied .card {
  padding-top: 20px;
  padding-bottom: 20px;
}

.access_denied .card p:nth-of-type(2) {
  font-size: var(--font-size-14);
}

#rmi_inward_link {
  font-size: var(--font-size-14);
}


#production_planning .react-calendar {
  border: none;
}

#production_planning .react-calendar__navigation {
  margin-bottom: 0;
}

#production_planning .react-calendar__month-view__weekdays {
  font-size: 0.70em;
}

#production_planning .react-calendar__navigation {
  font-size: 1em;
}

#production_planning .react-calendar__tile {
  padding: 6px 6.6667px;
}


#production_planning .rbc-row-segment {
  font-size: 12px;
  /* color: ; */
}

/* #production_planning .rbc-event {
  background-color: var(--red-normal-hover);
} */

#production_planning .react-calendar__navigation__label {
  font-size: 14px;
}

.calender_layoutv3 .operation_cards {
  height: 400px;
  overflow-y: auto;
  padding-right: 10px;
}



.calender_layoutv3 .operation_cards .card.normal {
  background: var(--grey-light-active);
}



.calender_layoutv3 .operation_cards .card.active {
  background: #A0D683;
  transition: 0.4s ease-in;
}

.calender_layoutv3 .operation_cards .card {
  padding: 4px 10px 8px 10px;
  border: 1px solid #dee2e6;
  margin-top: 12px;
}

.calender_layoutv3 .operation_cards .card p {
  font-size: var(--font-size-14);
  /* font-weight: var(--font-weight-bold); */
  letter-spacing: 0.5px;
  color: var(--blue-darker);
}

.calender_layoutv3 .operation_cards .notes {
  font-size: var(--font-size-14);
  color: var(--grey-dark);
}

.calender_layoutv3 .green_indicator {
  background: #A0D683;
  height: 14px;
  width: 14px;
  border-radius: 2px;
  margin-top: 3px;
}

.stepTwo .notes {
  font-size: var(--font-size-14);
  color: var(--grey-dark);
}

.stepTwo .red_indicator {
  background: #F6635C;
  height: 14px;
  width: 14px;
  border-radius: 2px;
  margin-top: 3px;
}

.stepTwo .yellow_indicator {
  background: #FFBB5C;
  height: 14px;
  width: 14px;
  border-radius: 2px;
  margin-top: 3px;
}


.react-datepicker__portal {
  z-index: 1000 !important;
  /* Adjust DatePicker's portal z-index if necessary */
}

.my-sweetalert {
  z-index: 1050 !important;
  /* Ensure SweetAlert appears above the portal */
}

.measurement-row {
  margin-bottom: 10px;
}
.measurement-label {
  display: inline-block;
  width: 150px;
}
.measurement-input {
  width: 50px;
}

@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
