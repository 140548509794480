@charset "UTF-8";
body {
  font-family: "Open Sans";
}

.stepper-horizontal {
  display: table;
  width: 60%;
  margin: 0 auto;
}
.stepper-horizontal .step {
  display: table-cell;
  position: relative;
  padding: 1.5rem;
  z-index: 2;
  width: 25%;
}
.stepper-horizontal .step:last-child .step-bar-left, .stepper-horizontal .step:last-child .step-bar-right {
  display: none;
}
.stepper-horizontal .step .step-circle {
  width: 2rem;
  height: 2rem;
  margin: 0 auto;
  border-radius: 50%;
  text-align: center;
  line-height: 1.75rem;
  font-size: 1rem;
  font-weight: 600;
  z-index: 2;
  border: 2px solid #d9e2ec;
}
.stepper-horizontal .step.done .step-circle {
  background-color: #141414;
  border: 2px solid #0d0e0d;
  color: #ffffff;
}
.stepper-horizontal .step.done .step-circle:before {
  content: '\2713'; /* Unicode character for checkmark */
    color: rgb(230, 243, 230); /* Change color as needed */
    font-size: 1.2em; /* Change font size as needed */
    display: inline-block; /* Ensures that the checkmark is displayed inline with text */
    margin-right: 2px; 
}
.stepper-horizontal .step.done .step-circle * {
  display: none;
}
.stepper-horizontal .step.done .step-title {
  color: #102a43;
}
.stepper-horizontal .step.editing .step-circle {
  background: #ffffff;
  border-color: #111111;
  color: #111111;
}
/* .stepper-horizontal .step.editing .step-title {
  color: #101111;
  text-decoration: underline;
} */
.stepper-horizontal .step .step-title {
  margin-top: 0.7rem;
  font-size: 14px;
  font-family: "roboto-bold", sans-serif !important;
}
.stepper-horizontal .step .step-title,
.stepper-horizontal .step .step-optional {
  text-align: center;
  color: #829ab1;
}
.stepper-horizontal .step .step-optional {
  font-size: 0.75rem;
  font-style: italic;
  color: #9fb3c8;
}
.stepper-horizontal .step .step-bar-left,
.stepper-horizontal .step .step-bar-right {
  position: absolute;
  top: calc(2rem + 5px);
  height: 5px;
  background-color: #d9e2ec;
  border: solid #d9e2ec;
  border-width: 2px 0;
}
.stepper-horizontal .step .step-bar-left {
  width: calc(100% - 2rem);
  left: 50%;
  margin-left: 1rem;
  z-index: -1;
}
.stepper-horizontal .step .step-bar-right {
  width: 0;
  left: 50%;
  margin-left: 1rem;
  z-index: -1;
  transition: width 500ms ease-in-out;
}
.stepper-horizontal .step.done .step-bar-right {
  background-color: #1b1d1c;
  border-color: #171818;
  z-index: 3;
  width: calc(100% - 2rem);
}