.tree-node {
  /* margin-left: 20px; */
  /* border-left: 1px dashed #aaa; */
  /* border-top: 1px solid #aaa; */
  /* padding-left: 8px; */
  /* padding: 10px 0px 10px 0px; */
  position: relative;
  font-size: 14px;
}

.node-toggle {
  cursor: pointer;
  transition: color 0.3s;
}

.child-nodes {
  /* margin-left: 20px; */
  list-style: none;
  /* padding-left: 8px; */
}

.child-nodes>li {
  /* margin: 5px 0; */
}

.internal_com .tree-node ul {
  margin-left: 0px !important;
  padding-left: 0px;
  margin-bottom: 0px;
}

.internal_com .tree-node .child-nodes li {
  margin-left: 0px !important;
  padding-left: 15px;
}

.internal_com .tree-node #company_name {
  font-family: "roboto-bold", sans-serif !important;
}